import React from 'react'

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__dev">
        </div>
      </div>
    </footer>
  )
}
