import lecture1 from './Lecture1.zip'
import lecture2 from './Lecture2.zip'
import lecture3 from './Lecture3.zip'
import lecture4 from './Lecture4.zip'
import lecture5 from './Lecture5.zip'
import lecture6 from './Lecture6.zip'
import lecture7 from './Lecture7.zip'
import lecture8 from './Lecture8.zip'
import lecture9 from './Lecture9.zip'
import lecture10 from './Lecture10.zip'

export default {
  lecture1, lecture2, lecture3, lecture4, lecture5, lecture6, lecture7, lecture8, lecture9, lecture10,
}
