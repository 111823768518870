import React from 'react';

import './App.css';
import Layout from './app/layout/Layout';

function App() {

  return (
    <>
      <Layout />
    </>
  );
}

export default App;
